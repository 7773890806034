import './public-path';
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { registerMicroApps, start, setDefaultMountApp } from 'qiankun';
// import * as Sentry from '@sentry/react';
import 'element-ui/lib/theme-chalk/index.css';

import Main from './components/Main';
import { getMicroApps, getSubAppUrl } from '@/config/microApp';

import { loginUrl } from './utils/base';

// import "@/utils/rem";

import "@/assets/style/index.less";
// iconfont-Symbol模式需要
import "@/assets/fonts/iconfontColor/iconfont";

// redux
import store from "./stores";

// 处理登录逻辑
import { getUrlParams, delUrlParam } from "./utils";
import { publicPath } from "./utils/base";

const { token } = getUrlParams(decodeURIComponent(window.location.href));
if(token) {
  localStorage.setItem('Authorization', token);
  localStorage.setItem('token', token);
  window.history.replaceState("", "", delUrlParam('token'));
}

if(!localStorage.getItem('token')) {
  window.open(`${loginUrl}?jump_url=${encodeURIComponent(window.location.href)}`, '_self', 'toolbar=yes, menubar=yes, scrollbars=yes, resizable=yes, location=yes, status=yes');
}


// router
// import { rootRoutes, renderRoutes } from "@/routes";

console.log('getMicroApps()', getMicroApps());

registerMicroApps(getMicroApps());


export const initRender = (url = '/product/product-portal/#/Home') => {
  start({
    sandbox: {
      // 启用样式隔离
      experimentalStyleIsolation: true,
    },
  });

  if (window.location.pathname === publicPath && window.location.hash === "") {
    setDefaultMountApp(url);
    // // 默认拉起超级设备管理平台子应用
    // if (localStorage.getItem("INIT_SYSTEM") === "1") {
    //   setDefaultMountApp("/#/khlinks/");
    // } else {
    //   const subAppUrl = getSubAppUrl("/kh_overview/device-count");
    //   if (subAppUrl) {
    //     setDefaultMountApp(subAppUrl.startsWith("/") ? subAppUrl : "/" + subAppUrl);
    //   }
    // }
  }
};

const root = ReactDOM.createRoot(document.getElementById("main-root") as HTMLElement);

root.render(
  <Provider store={store}>
    <ConfigProvider locale={ zhCN }>
      {/* <App style={{height: '100%'}}> */}
      <Suspense>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </Suspense>
      {/* </App> */}
    </ConfigProvider>
  </Provider>
);